<template>
  <div class="media-live-link-pane">
    <el-form label-width="70px"
             class="small-form"
             size="small"
             @submit.native.prevent>
      <el-form-item label="直播链接：">
        <el-input type="textarea" v-model="liveLink"
                  @change="$emit('input', liveLink)"
                  style="max-width: 500px; "
                  class="live-link" :rows="4"
                  placeholder="格式如：https://xxxxxx.m3u8" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "MediaLiveLinkPane",
    props: {
      value: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        /* 测试的 m3u8 链接: https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8 */
        liveLink: ""
      }
    },
    watch: {
      value(val) {
        this.liveLink = val;
      }
    },
    created() {
      this.liveLink = this.value;
    }
  }
</script>

<style lang="scss" scoped>
  .media-live-link-pane {
    .el-form {
      margin: 0 auto;
      max-width: 500px;
    }

    .el-form-item {
      padding-top: 100px;
    }

    .live-link {
      ::v-deep .el-textarea__inner {
        max-height: 150px;
      }
    }
  }
</style>
