<template>
  <div class="media-phone-upload-pane">
    <div class="media-phone-upload-scan">
      <div class="upload-code">
        <el-image class="scan-code" :src="code" fit="contain"></el-image>
      </div>
      <div class="scan-tip">
        <span>扫一扫二维码，快速上传手机图片 </span>
        <el-button type="text" size="medium" @click="doRefresh">刷新</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MediaPhoneUploadPane",
    props: {
      onRefresh: {
        type: Function
      },
      refresh: {
        type: Boolean,
        default: false
      },
      code: {
        type: String,
      },
      // loading: {
      //   type: Boolean,
      //   default: false
      // }
    },
    data() {
      return {

      }
    },
    watch: {
      refresh(newValue) {
        if (!this.code || newValue) {
          this.doRefresh()
        }
      },
    },
    methods: {
      doRefresh() {
        this.onRefresh()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .media-phone-upload-pane {
    display: flex;
    justify-content: center;
    align-items: center;

    .media-phone-upload-scan {
      .upload-code {
        width: 210px;
        height: 210px;
        padding: 10px;
        margin: auto;
        border: 1px solid #d8dce6;

        .scan-code {
          width: 100%;
          height: 100%;
        }
      }

      .scan-tip {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #303133;
      }
    }
  }
</style>
