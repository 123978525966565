<template>
  <div class="media-local-upload-pane flex-col">
    <el-form
      size="small"
      :inline="false"
      label-position="right"
      label-width="85px"
      :rules="rules"
      class="small-form"
      @submit.native.prevent
    >
      <el-form-item
        label="所在分组："
        v-if="myFolders && myFolders.length"
        prop="pid"
      >
        <el-select v-model="pid">
          <el-option
            v-for="folder in myFolders"
            :key="folder.id"
            :value="folder.id"
            :label="folder.title"
          >
          </el-option>
        </el-select>
        <!-- 新建分组 -->
        <confirm-popover ref="newFolder" :on-confirm="saveNewFolderPopover">
          <el-form-item label="分组名称">
            <el-input
              maxlength="10"
              v-model="popover.title"
              v-focus
              show-word-limit
              @keyup.enter.native="pressEnter"
            ></el-input>
          </el-form-item>
          <el-button slot="reference" type="text">+新建分组</el-button>
        </confirm-popover>
      </el-form-item>
      <el-form-item :label="'上传' + typeText + '：'" prop="fileList">
        <el-upload
          v-if="!status"
          class="upload-area flex-col"
          ref="uploadArea"
          drag
          action="#"
          multiple
          :on-remove="handleRemove"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :http-request="customUpload"
          :accept="acceptList"
          :auto-upload="true"
          :on-change="afterInputFiles"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <template v-else>
          <el-progress
            v-if="!proError"
            type="circle"
            :percentage="percentage"
            :color="colors"
          ></el-progress>
          <el-progress
            v-else
            type="circle"
            :percentage="percentage"
            status="exception"
          ></el-progress>
          <!--          <div class="info">-->
          <!--            <span v-if="!proError" class="info">上传中...</span>-->
          <!--            <el-button v-else size="small" @click="reset">重 试</el-button>-->
          <!--          </div>-->
        </template>
      </el-form-item>
    </el-form>
    <div class="el-dialog__footer">
      <el-button size="small" @click="$emit('cancel')">取消</el-button>
      <el-button
        :disabled="!files.length"
        type="success"
        size="small"
        @click="openCutter()"
        >裁剪上传</el-button
      >
      <el-button
        size="small"
        type="primary"
        @click="toUpload"
        :loading="status && !proError"
      >
        {{ status ? (proError ? '重试' : '上传中') : '上传' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import ConfirmPopover from '../Default/ConfirmPopover'
import { mediaCreateFolder } from '../../../modules/common/api/media'
export default {
  name: 'MediaLocalUploadPane',
  components: { ConfirmPopover },
  inject: ['getRatioList', 'getRatio', 'mediaKey'],
  props: {
    folders: {
      type: Array,
    },
    // 用于判断上传的文件类型是否正确
    type: {
      type: Array,
      default: ['image'],
    },
    typeText: {
      type: String,
      default: '图片',
    },
    onUpload: {
      type: Function,
    },
    onComplete: {
      type: Function,
    },
  },
  data() {
    return {
      // 所属文件夹
      pid: 0,
      fileList: [],
      files: [],
      status: false, // 上传状态
      percentage: 0, // 上传进度条
      proError: false, // 上传出错
      colors: [
        { color: '#3576FF', percentage: 90 },
        { color: '#13ce66', percentage: 100 },
      ],
      typeArr: {
        image: ['jpg', 'png', 'jpeg', 'bmp', 'gif', 'svg', 'webp'],
        audio: ['wav', 'mp3', 'ogg', 'aac', 'webm'],
        video: ['ogg', 'mpeg4', 'webm', 'mp4', 'flv'],
        file: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
      },
      rules: {
        pid: [{ required: true, message: '请选择分组', trigger: 'blur' }],
        fileList: [{ required: true, message: '请选择文件' }],
      },
      inputLoading: false,

      filesLen: 0,

      // 通用的popover弹窗变量
      popover: {
        // 移动（添加）到
        active: -1,
        // 编辑、新建
        title: '',
      },
    }
  },
  computed: {
    ratio() {
      return this.getRatio()
    },
    ratioList() {
      return this.getRatioList()
    },
    myFolders: function () {
      // 过滤掉"全部"
      return this.folders.filter((item) => item.id !== -1)
    },
    acceptList() {
      let arr = []
      this.type.forEach((t) => {
        if (this.typeArr[t]) {
          this.typeArr[t].forEach((u) => {
            // 2024年10月19日15:30:43 加了 audio 是因为不加的话，drag 失效了没法用
            if (['file', 'audio'].includes(t)) arr.push(`.${u}`)
            else arr.push(`${t}/${u}`)
          })
        }
      })
      if (arr.length) return arr.join(',')
      return '*'
    },
  },
  methods: {
    pressEnter() {
      this.$refs.newFolder.confirm()
    },
    saveNewFolderPopover() {
      return mediaCreateFolder({
        title: this.popover.title,
        pid: 0,
      })
        .then((res) => {
          // 更新分组数据
          this.$store.dispatch('media/getFoldersData', {
            callback: () => {
              this.pid = Number(res.data.id)
            }
          })
          this.popover.title = ''
        })
        .catch((err) => {})
    },
    afterInputFiles(f, fList) {
      // console.log(this.fileList) // fileList 是空的
      // console.log(fList)
      // console.log(f, fList.length);

      // this.inputLoading = true

      this.filesLen = fList.length

      setTimeout(() => {
        if (this.filesLen === fList.length) {
          console.log('1000毫秒内没有动静')
          this.inputLoading = false
          // this.$nextTick(() => {
          //   this.openCutter(fList) // 裁剪
          // }) // 自动打开裁剪弹窗？
        }
      }, 1000)
    },
    getObjectURL(blob) {
      var url = null
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(blob)
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(blob)
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(blob)
      }
      return url
    },
    // 文件类型转base64
    fileToBase64(file, callback) {
      const reader = new FileReader()
      reader.onload = function (evt) {
        if (typeof callback === 'function') {
          callback(evt.target.result)
        } else {
          console.log('我是base64:', evt.target.result)
        }
      }
      reader.readAsDataURL(file)
    },
    openCutter(urls) {
      console.log(this.fileList)
      console.log(this.files)
      console.log(urls)

      Promise.all(
        [...(urls || this.files)].map(async (f) => {
          return await new Promise((resolve) => {
            // this.fileToBase64(f.raw, (base64) => {
            //   resolve(base64)
            // })
            // resolve({
            //   name: f.raw.name,
            //   // 可用于 new Image() onload
            //   src: getObjectURL(f.raw)
            // })
            // try {
            //   resolve(this.getObjectURL(f.raw || f))
            // } catch (e) {
            //   console.log(e)
            this.fileToBase64(f.raw || f, (base64) => {
              resolve(base64)
            })
            // }
          })
        })
      )
        .then((res) => {
          console.log('以下是本地图片转base64')
          console.log(res)
          this.$store.dispatch('crop/openMediaCropDialog', {
            imgSrc: res,
            ratio: this.ratioList[0] || '',
            ratioList: this.ratioList || [],
            mediaKey: this.mediaKey
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    reset() {
      this.files = [] //文件数组清空
      this.fileList = [] //el组件绑定数组清空
      this.percentage = 0 //进度条归零
      this.proError = false //错误关闭
      this.status = false //上传状态还原初始值
    },
    // 触发上传
    toUpload() {
      if (this.status && this.proError) {
        this.reset()
      } else {
        if (this.files.length) {
          // setTimeout(() => {
          this.doUpload()
          // }, 800)
        } else {
          this.$message.info('上传内容为空，请选择要上传的文件！')
        }
      }
    },
    /**
     * 返回数组就是上传完了。。。
     * */
    isArrayFn(value) {
      if (typeof Array.isArray === 'function') {
        return Array.isArray(value)
      } else {
        return Object.prototype.toString.call(value) === '[object Array]'
      }
    },
    // 文件上传
    doUpload() {
      this.status = true
      let endUp = 0 // 已经上传几个
      this.onUpload({
        files: this.files,
        extra: {
          pid: this.pid,
        },
        callback: (res = {}) => {
          const { code } = res
          if (code || this.isArrayFn(res)) {
            this.percentage = 100
            setTimeout(() => {
              this.$message.success('文件上传成功')
              this.status = false
              // 还原
              this.reset()
              // 更新列表，关闭弹窗在此设置
              this.onComplete &&
                this.onComplete(this.isArrayFn(res) ? res : [res.data])
              this.loading = false
            }, 500)
          } else {
            this.proError = true
          }
        },
        progress: (num) => {
          this.percentage = num
        },
      })
    },
    /**
     * 覆盖默认的上传行为，可以自定义上传的实现
     * 将文件依次传入数组
     * 触发顺序为on-change -> before-upload -> http-request
     */
    customUpload(file) {
      console.log('http-request')
      this.files.push(file.file)
      // this.toUpload()
    },
    /**
     * 文件列表移除文件时的钩子
     * @param file
     * @param fileList
     */
    handleRemove(file, fileList) {
      const rmUid = file.uid
      const index = this.files.findIndex((item) => item.uid === rmUid)
      this.files.splice(index, 1)
    },
    /**
     * 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
     * 检验文件类型不符合则取消上传
     * @param file
     * @returns {boolean}
     */
    beforeUpload(file) {
      let flag = true
      // Note: 文件后缀名统一用小写判断
      const fileType = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLowerCase()
      // 所有可上传的文件类型
      let typeArr = []
      this.type.forEach((t) => {
        this.typeArr[t] && typeArr.push(...this.typeArr[t])
      })
      if (typeArr && !typeArr.includes(fileType)) {
        flag = false
        this.$message.info('当前文件格式不正确，请选择指定的文件格式')
      }
      return flag
    },
  },
}
</script>

<style lang="scss">
.media-local-upload-pane {
  .el-form {
    margin: 0 auto;
    max-width: 500px;
    flex: 1;

    .el-form-item__content {
      display: block;
      vertical-align: unset;
    }
  }

  .el-dialog__footer {
    padding: 0;
  }

  .upload-area {
    max-height: 300px;

    .el-upload-list {
      flex: 1;
      overflow: auto;
    }

    .el-upload-list__item:hover {
      outline: none;
    }
  }
}
</style>
