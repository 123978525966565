<template>
  <div class="media-external-link-pane">
    <el-form label-width="70px"
             class="small-form"
             size="small"
             @submit.native.prevent>
      <el-form-item label="外部链接：">
        <el-input type="text" placeholder="格式如：<iframe src=... ></iframe>"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "MediaExternalLinkPane",

  }
</script>

<style lang="scss" scoped>
  .media-external-link-pane {
    .el-form {
      margin: 0 auto;
      max-width: 500px;
    }
  }
</style>
