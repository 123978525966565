<template>
  <div class="media-photo-library-pane">
    <!-- 单选 -->
    <el-radio-group class="media-dialog-list" v-model="myValue[0]" v-loading="loading" v-if="!multiple"
                    @change="handleCheck([$event])">
      <div class="media-dialog-item" v-for="(item, index) in list.data" :key="item.id">
        <list-media-item :label="item" :item="item" is-dialog @open-crop="handleCrop(item.url, item.pid)" />
      </div>
    </el-radio-group>
    <!-- 多选 -->
    <el-checkbox-group class="media-dialog-list" v-else v-model="myValue" v-loading="loading"
                       @change="handleCheck($event)">
      <div class="media-dialog-item" v-for="(item, index) in list.data" :key="item.id">
        <list-media-item :label="item" :item="item" multiple is-dialog
                         @open-crop="handleCrop(item.url, item.pid)" />
      </div>
    </el-checkbox-group>
    <div class="media-dialog-pager">
      <el-pagination background v-if="list && list.data" layout="prev, pager, next, jumper"
                     :page-size="query.page_size" :current-page="query.page" :total="list.total" hide-on-single-page
                     @current-change="doFilter('page', $event)" />
    </div>
<!--    <crop-dialog :config-api="uploadUrl || '/admin/admin/media/requestUpload'" is-dialog @refresh="$emit('refresh')" />-->
  </div>
</template>

<script>
  import ListAudio from "./ListAudio";
  import ListMediaItem from "./ListMediaItem";
  import CropDialog from "./CropDialog";

  export default {
    name: "MediaPhotoLibraryPane",
    inject: ['uploadUrl', 'getMax', 'multiple', 'mediaKey'],
    components: { CropDialog, ListMediaItem, ListAudio },
    props: {
      value: {
        type: Array,
        required: true
      },
      // 超出限制提示
      info: {
        type: String,
        default: "",
      },
      onQueryFree: {
        default: function () {
          return function () {}
        }
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        list: {
          data: []
        },
        query: {
          page_size: 10,
          page: 1,
        },
        loading: false,
        myValue: [],
        enabled: [],
      }
    },
    computed: {
      idList() {
        return this.myValue.map((item) => item.id);
      },
      max() {
        return this.getMax()
      }
    },
    watch: {
      value(val) {
        this.myValue = val
      },
      active(val) {
        if (val) {
          if (this.list.data.length === 0) {
            this.getList()
          }
        }
      }
    },
    methods: {
      handleCrop(url) {
        this.$store.dispatch("crop/openMediaCropDialog", { imgSrc: url, mediaKey: this.mediaKey });
      },
      handleCheck(e) {
        this.$emit('input', e);
      },
      handleSingleCheck() {
        this.enabled = [];
        this.list?.data.forEach((item, index) => {
          if (this.idList.indexOf(item.id) !== -1) {
            this.$set(this.enabled, index, true);
          }
        });
      },
      doFilter(key, val) {
        if (key && val) {
          this.query[key] = val
          this.getList()
        }
      },
      getList() {
        this.loading = true;
        this.onQueryFree(this.query).then(res => {
          this.list = res.data
          /* 全选的时候用到 */
          this.$emit('list-info', res.data)
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.media-photo-library-pane {
  .media-dialog-list {
    width: 100%;
    height: 348px;
    overflow-y: auto;
    display: grid;
    /*grid-template-columns: repeat(auto-fill, 170px);*/
    grid-template-columns: repeat(5, minmax(168px, 20%));
    /*grid-template-rows: repeat(2, 1fr);*/
    grid-row-gap: 16px;
    grid-column-gap: 12px;

    .media-dialog-item {
      height: 166px;
      margin-right: 0;

      .el-radio__input {
        z-index: 1;
      }

      ::v-deep.el-radio__input {
        z-index: 1;
      }
    }

    & + .media-dialog-pager {
      padding-top: 24px;
    }
  }

  .media-dialog-pager {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 15px;
  }
}
</style>
