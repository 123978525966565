<template>
  <div class="media-list-pane">
    <!-- 单选 -->
    <el-radio-group class="media-dialog-list" v-model="myValue[0]" v-loading="loading" v-if="!multiple"
      @change="handleCheck([$event])">
      <div class="media-dialog-item" v-for="(item, index) in list.data" :key="item.id">
        <list-media-item :label="item" :item="item" is-dialog @open-crop="handleCrop(item.url, item.pid)" />
      </div>
    </el-radio-group>
    <!-- 多选 -->
    <el-checkbox-group class="media-dialog-list" v-else v-model="myValue" v-loading="loading"
      @change="handleCheck($event)">
      <div class="media-dialog-item" v-for="(item, index) in list.data" :key="item.id">
        <list-media-item :label="item" :item="item" multiple is-dialog :disabled="myValue.length >= max && !myValue.find(mv => mv.id === item.id)"
                         @open-crop="handleCrop(item.url, item.pid)" />
      </div>
    </el-checkbox-group>
    <div class="media-dialog-pager">
      <el-pagination background v-if="list && list.data" layout="sizes, prev, pager, next, jumper" :page-sizes="[10, 15, 30, 50, 100]"
        :page-size="query.page_size" :current-page="query.page" :total="list.total" hide-on-single-page
        @current-change="doFilter('page', $event)" @size-change="doFilter('page_size', $event)">
      </el-pagination>
    </div>
<!--    <crop-dialog :config-api="uploadUrl || '/admin/admin/media/requestUpload'" is-dialog @refresh="doFilter('page', 1)" />-->
  </div>
</template>

<script>
import ListAudio from "./ListAudio";
import ListMediaItem from "./ListMediaItem";
import CropDialog from "./CropDialog";

export default {
  inject: ['uploadUrl', 'getMax', 'multiple', 'mediaKey'],
  components: { CropDialog, ListMediaItem, ListAudio },
  props: {
    // v-model
    value: {
      type: Array,
    },
    query: {
      type: Object,
    },
    list: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    onQuery: {
      type: Function,
    },
    // 超出限制提示
    info: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myValue: [],
      enabled: [],
    };
  },
  computed: {
    idList() {
      return this.myValue.map((item) => item.id);
    },
    max() {
      return this.getMax()
    }
  },
  watch: {
    value(val) {
      this.myValue = val;
    },
  },
  methods: {
    handleCrop(url) {
      this.$store.dispatch("crop/openMediaCropDialog", { imgSrc: url, mediaKey: this.mediaKey });
    },
    doFilter(key, value) {
      // 清空列表数据
      this.$emit("input", []);
      if (key) {
        this.query[key] = value;
      }
      this.onQuery(this.query);
    },
    handleCheck(e) {
      // if (this.multiple && this.max === 1) {
      //   if (this.myValue.length > 1) {
      //     // 只返回最后一个选中的
      //     this.$emit("input", [this.myValue[this.myValue.length - 1]]);
      //     return;
      //   }
      // } else if (this.multiple && this.max > 1) {
      //   if (this.myValue.length > this.max) {
          // this.$message.info(this.info);
          // this.$emit(
          //   "input",
          //   e.filter((el, index) => index !== e.length - 1)
          // );
          // return;
        // }
      // }
      this.$emit("input", e);
    },
    handleSingleCheck() {
      this.enabled = [];
      this.list?.data.forEach((item, index) => {
        if (this.idList.indexOf(item.id) !== -1) {
          this.$set(this.enabled, index, true);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-list-pane {
  .media-dialog-list {
    width: 100%;
    min-height: 348px;
    /*overflow-y: auto;*/
    display: grid;
    /*grid-template-columns: repeat(auto-fill, 170px);*/
    grid-template-columns: repeat(5, minmax(168px, 20%));
    /*grid-template-rows: repeat(2, 1fr);*/
    grid-row-gap: 16px;
    grid-column-gap: 12px;

    .media-dialog-item {
      height: 166px;
      margin-right: 0;

      .el-radio__input {
        z-index: 1;
      }

      ::v-deep.el-radio__input {
        z-index: 1;
      }
    }

    & + .media-dialog-pager {
      padding-top: 24px;
    }
  }

  /*@media (max-width: 900px) {*/
  /*  .media-dialog-list {*/
  /*    !*height: 530px;*!*/
  /*    grid-template-columns: repeat(4, minmax(100px, 25%));*/
  /*    !*grid-template-rows: repeat(3, 1fr);*!*/
  /*  }*/
  /*}*/

  .media-dialog-pager {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 15px;
  }
}
</style>
